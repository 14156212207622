<template>
  <form @submit="onSubmit" v-if="show">
    <h4>Book Development Service</h4>
    <div class="row mb-3">
      <b-form-group id="name" label="First Name:" label-for="name" class="col-sm-12">
        <b-form-input id="name" v-model="form.name" type="text" required></b-form-input>
      </b-form-group>
    </div>
    <div class="row mb-3">
      <b-form-group id="phone" label="Mobile Phone:" label-for="phone" class="col-sm-6">
        <b-form-input id="phone" v-model="form.phone" type="number" required></b-form-input>
      </b-form-group>
      <b-form-group id="email" label="Email address:" label-for="email" class="col-sm-6">
        <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
      </b-form-group>
    </div>
    <div class="row mb-3">
      <b-form-group id="postcode" label="Post Code:" label-for="postcode" class="col-sm-6">
        <b-form-input id="postcode" v-model="form.postcode" type="text" required></b-form-input>
      </b-form-group>
      <b-form-group id="selected" label="Choose Service:" label-for="selected" class="col-sm-6">
        <b-form-select v-model="form.selected" :options="options"  class="form-select" required></b-form-select>
      </b-form-group>
    </div>
    <div class="row mb-3">
      <b-form-group id="message" label="Message:" label-for="message" class="col-sm-12">
        <b-form-textarea id="message" v-model="form.message" placeholder="Describe Service you Looking for" rows="3" max-rows="6">
        </b-form-textarea>
      </b-form-group>
    </div>

    <b-button type="submit" variant="danger" class="mt-3">Submit</b-button>
  </form>
</template>

<script>
export default {
  name: "StartWebdev",
  data() {
    return {
      form:{
        name:'',
        email:'',
        phone:'',
        postcode:'',
        selected:null,
        message:'',
      },
      options:[
        {value: null, text: 'Please select an option'},
        {value:1,text:'Web Development'},
        {value:2,text:'Web Design'},
        {value:3,text:'Redsign Website'},
        {value:4,text:'Logo Design'},
        {value:5,text:'SEO'},
        {value:6,text:'Digital Marketing'},
        {value:7,text:'SMO'},
        {value:8,text:'Mobile App Development'},
        {value:9,text:'Others'},
      ],
      show:true,
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
  }
}
</script>

<style scoped>

</style>