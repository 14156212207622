import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    userToken: '', // User token
    userData: '', // User data
    isAuthenticated: false,
  },
  mutations: {
    SET_USER_TOKEN(state, token) {
      state.userToken = token;
      localStorage.setItem('userToken', token);
      state.isAuthenticated = true;
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData;
      localStorage.setItem('userData', JSON.stringify(userData));
    },
    CLEAR_USER(state) {
      state.userToken = '';
      state.userData = '';
      localStorage.clear('userToken'); // Remove userToken from local storage
      localStorage.clear('userData'); // Remove userData from local storage
    },
  },
  actions: {
    login({ commit }, { token, userData }) {
      commit('SET_USER_TOKEN', token);
      commit('SET_USER_DATA', userData);
    },
    logout({ commit }) {
      commit('CLEAR_USER');
    },
  },
});
// Retrieve stored state from local storage
const userToken = localStorage.getItem('userToken');
const userData = JSON.parse(localStorage.getItem('userData'));

if (userToken) {
  store.commit('SET_USER_TOKEN', userToken);
}

if (userData) {
  store.commit('SET_USER_DATA', userData);
}
export default store;
