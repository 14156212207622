<template>
  <div class="home">
    <section class="hero borg">
      <v-container class="py-5">
        <v-row class="align-center">
          <v-col cols="12" md="8">
            <h1 class="display-5 fw-semibold"><b-icon icon="person"></b-icon>Are You Candidate? </h1>
            <h2 class="display-5 fw-semibold text-white">Looking for Job</h2>
            <p>
              Whether you're looking for the ultimate dream job, or just a new challenge, you haven't worked with a
              recruitment agency like us before.
            </p>
            <v-btn v-b-modal.applyjob size="lg" class="rounded-5 bg-dark text-white">Apply Now
              <b-icon icon="telegram"></b-icon>
            </v-btn>
            <b-modal id="applyjob" hide-footer hide-header size="lg">
              <ApplyJob/>
            </b-modal>
          </v-col>
          <b-col md="4">
            <v-img src="../assets/job-search.png" alt="candidate" class="img-fluid"></v-img>
          </b-col>
        </v-row>
      </v-container>

    </section>
    <section class="serv py-5">
      <b-container>
        <b-row class="g-3">
          <b-col cols="6" lg="3" class="text-center" v-for="service in services" :key="service.id">
            <a class="card shadow p-2 rounded-3 text-decoration-none link-dark text-uppercase ls1" :href="('#'+service.servicetag)">
              <img :src=(url+service.service_img_url) width="80" class="pb-2 mx-auto" :alt="service.service_name">
              <div>{{service.service_name}}</div>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="recruit" class="pt-5">
      <b-container>
        <h2 class="text-center display-5 fw-semibold">Recruitment Services</h2>
        <p class="text-center">
          Recruitment services refer to the process of finding and hiring qualified candidates for job vacancies within
          organizations. These services are typically offered by recruitment agencies or staffing firms that specialize
          in connecting job seekers with employers.
        </p>
        <b-row class="justify-content-center mb-3">
          <b-col cols="12" lg="12" class="text-center mb-3">
            <div class="display-6"><span class="corg">Search</span> Jobs By:
            </div>
          </b-col>
        </b-row>
        <b-row>
          <v-tabs v-model="tab" flat centered>
            <v-tab class="fs-2 btn">Category</v-tab>
            <v-tab class="fs-2">Job Type</v-tab>
            <v-tabs-slider color="warning"></v-tabs-slider>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <b-row class="g-4 pt-3 justify-content-center">
                <b-col cols="12" lg="3" v-for="jobcat in jobcats" :key="jobcat.id">
                  <router-link :to="{ name:'jobcat',
                  params:{id:jobcat.id,jobcatname:jobcat.jobcat_name,jobcat_url:jobcat.jobcat_url,
                    jobimage:jobcat.jobcat_img_url}}"
                               class="text-decoration-none">
                    <div class="card text-center cats">
                      <img :src=(url+jobcat.jobcat_img_url) class="card-img-top" :alt="jobcat.jobcat_name">
                      <div class="card-footer borg tbs">{{ jobcat.jobcat_name }}</div>
                    </div>
                  </router-link>
                </b-col>
              </b-row>
            </v-tab-item>
            <v-tab-item>
              <b-row class="g-4 pt-3 justify-content-center">
                <b-col cols="12" lg="3" v-for="jobtype in jobtypes" :key="jobtype.id">
                  <router-link :to="{ name:'jobtype',
            params:{id:jobtype.id,jobtypetitle:jobtype.jobtype_title,jobtype_slug:jobtype.jobtype_slug,jobtypeimage:jobtype.jobtype_img_url}}"
                               class="text-decoration-none">
                    <div class="card text-center cats">
                      <img :src=(url+jobtype.jobtype_img_url) class="card-img-top" :alt="jobtype.jobtype_title">
                      <div class="card-footer borg link-dark">{{ jobtype.jobtype_title }}</div>
                    </div>
                  </router-link>
                </b-col>
              </b-row>
            </v-tab-item>
          </v-tabs-items>
        </b-row>
      </b-container>
      <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 19.6" preserveAspectRatio="none">
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"></path>
          <path class="shape-fill" d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"></path>
        </svg>
      </div>
    </section>
    <section id="logistics" class="pt-5 bg-light">
      <b-container>
        <h2 class="text-center display-5 fw-semibold">Logistics & Supply Chain</h2>
        <p class="text-center">
          Logistics and supply chain refer to the process of planning, implementing, and controlling the movement and
          storage of goods and services from the point of origin to the point of consumption. This process involves a
          range of activities, including transportation, inventory management, warehousing, and distribution.
        </p>
        <b-row class="align-items-center pt-5">
          <b-col cols="12" lg="7">
            <h2 class="h3 corg">Express Delivery Services and International Shipping</h2>
            <h3 class="h4">Fast. Door-to-Door. Courier Delivered.</h3>
            <p>
              Courier services refer to the transportation and delivery of packages and documents from one location to another. These services are typically offered by companies that specialize in courier delivery, including both domestic and international delivery options.
            </p>
            <p>
              Courier services offer a range of options to meet different delivery needs, including same-day delivery, next-day delivery, and express delivery. They may also offer tracking services so that customers can monitor the progress of their package or document delivery.
            </p>
            <b-button v-b-modal.bookparcel variant="warning" size="lg" class="rounded-5">Book Parcel Now
              <b-icon icon="box"></b-icon>
            </b-button>
            <b-modal id="bookparcel" hide-footer hide-header size="lg">
              <BookParcelnow/>
            </b-modal>
          </b-col>
          <b-col cols="12" lg="5">
            <img src="../assets/logistics1.jpg" alt="logistics" class="img-fluid">
          </b-col>
        </b-row>
        <b-row class="pt-5 justify-content-center">
          <b-col cols="12" lg="3" class="text-center">
            <div class="card">
              <img src="../assets/air-f.jpg" alt="airf" class="card-img-top">
              <div class="card-footer">Air Freight</div>
            </div>
          </b-col>
          <b-col cols="12" lg="3" class="text-center">
            <div class="card">
              <img src="../assets/sea-f.jpg" alt="airf" class="card-img-top">
              <div class="card-footer">Sea Freight</div>
            </div>
          </b-col>
          <b-col cols="12" lg="3" class="text-center">
            <div class="card">
              <img src="../assets/road-f.jpg" alt="airf" class="card-img-top">
              <div class="card-footer">Road Freight</div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 19.6" preserveAspectRatio="none">
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"></path>
          <path class="shape-fill" d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"></path>
        </svg>
      </div>
    </section>
    <section id="facs" class="pt-5">
      <b-container>
        <h2 class="text-center display-5 fw-semibold">Cleaning & Facilities Management</h2>
        <b-row class="align-items-center pt-5">
          <b-col cols="12" lg="7">
            <h2 class="corg">Commercial Services</h2>
            <p>
              Available around the clock at a time to suit you, our commercial cleaning services cover offices, restaurants, and more! All internal cleaning is taken care of and our specialist, DBS-checked cleaners are the first choice for many when it comes to office cleaning in London. Contracts are available for ongoing work, so give Josh Cleaning Services Ltd a call now and find out more!
            </p>
            <h5 class="corg">Our team can provide cleaning for:</h5>
            <div class="dfwrap">
              <span><i class="mdi mdi-silverware"></i>Restaurants</span>
              <span><i class="mdi mdi-store"></i>Shops</span>
              <span><i class="mdi mdi-office-building"></i>Offices</span>
              <span><i class="mdi mdi-school"></i>Schools</span>
              <span><i class="mdi mdi-playlist-plus"></i>Many More</span>
            </div>
            <h5 class="corg">While cleaning these locations, we can cover:</h5>
            <div class="dfwrap">
              <span><i class="mdi mdi-vacuum"></i>Vacuuming</span>
              <span><i class="mdi mdi-weather-dust"></i>Dusting</span>
              <span><i class="mdi mdi-gate-buffer"></i>Polishing and Buffing</span>
              <span><i class="mdi mdi-playlist-plus"></i>All Other Internal Cleaning</span>
            </div>
            <b-button v-b-modal.bookcleaning variant="warning" size="lg" class="rounded-5">Book Cleaning Service
              <i class="mdi mdi-broom"></i>
            </b-button>
            <b-modal id="bookcleaning" hide-footer hide-header size="lg">
             <BookCleaningservice/>
            </b-modal>
          </b-col>
          <b-col cols="12" lg="5">
            <img src="../assets/commercial1.png" alt="logistics" class="img-fluid">
          </b-col>
        </b-row>
        <b-row class="align-items-center pt-5">
          <b-col cols="12" lg="7">
            <h2 class="corg">Domestic Services</h2>
            <p>
              At Josh Cleaning Services Ltd, we cover all aspects of domestic cleaning for our clients in and around London. From vacuuming to polishing and dusting, we ensure that your domestic property is expertly cleaned from top to bottom. Contracts are available and our home cleaning services are pet-friendly for added convenience. Free quotes are available today so get in touch today for our full-home or room-specific services
            </p>
            <h5 class="corg">While cleaning these locations, we can cover:</h5>
            <div class="dfwrap">
              <span><i class="mdi mdi-vacuum"></i>Vacuuming</span>
              <span><i class="mdi mdi-weather-dust"></i>Dusting</span>
              <span><i class="mdi mdi-washing-machine"></i>Washing up</span>
              <span><i class="mdi mdi-toilet"></i>Bathroom Cleaning</span>
              <span><i class="mdi mdi-gate-buffer"></i>Polishing</span>
              <span><i class="mdi mdi-tumble-dryer"></i>Laundry</span>
              <span><i class="mdi mdi-window-closed-variant"></i>Internal Windows</span>
            </div>
            <b-button v-b-modal.bookcleaning variant="warning" size="lg" class="rounded-5">Book Cleaning Service
              <i class="mdi mdi-broom"></i>
            </b-button>
          </b-col>
          <b-col cols="12" lg="5">
            <img src="../assets/domestic1.png" alt="logistics" class="img-fluid">
          </b-col>
        </b-row>
      </b-container>
      <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 19.6" preserveAspectRatio="none">
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"></path>
          <path class="shape-fill" d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"></path>
        </svg>
      </div>
    </section>
    <section id="tech" class="pt-5 bg-light">
      <b-container>
        <h2 class="text-center display-5 fw-semibold">Technology & Engineering</h2>
        <h3 class="text-center fw-semibold">Our Partner</h3>
        <h4 class="text-center fw-semibold">Saradecoders UK</h4>
        <b-row class="align-items-center pt-5">
          <b-col cols="12" lg="7">
            <h2 class="corg">Web Development</h2>
            <p>
              Our Web development services include activities such as website design, front-end development, back-end development, database integration, content management system (CMS) development, and e-commerce website development. Web developers may use a range of programming languages and frameworks, such as HTML, CSS, JavaScript, PHP, and Ruby on Rails, to build websites and web applications.
            </p>
            <div class="dfwrap">
              <span><i class="mdi mdi-language-php"></i>Php</span>
              <span><i class="mdi mdi-vuejs"></i>VueJs</span>
              <span><i class="mdi mdi-wordpress"></i>Wordpress</span>
              <span><i class="mdi mdi-cart"></i>Shopping Cart</span>
              <span><i class="mdi mdi-laravel"></i>Laravel</span>
              <span><i class="mdi mdi-nodejs"></i>NodeJs</span>
              <span><i class="mdi mdi-react"></i>Reactjs</span>
            </div>
            <h2 class="corg">Web Designing</h2>
            <p>
              Our Web designing services include activities such as website layout design, Logo Design, graphic design, user interface design, user experience design, and responsive design. Web designers may use a range of design tools and software, such as Adobe Photoshop, Adobe Illustrator, Sketch, and Figma, to create website designs.
            </p>
            <div class="dfwrap">
              <span><i class="mdi mdi-pencil-ruler"></i>Logo</span>
              <span><i class="mdi mdi-select-color"></i>UI/UX</span>
              <span><i class="mdi mdi-language-html5"></i>HTMl5</span>
              <span><i class="mdi mdi-language-css3"></i>CSS3</span>
              <span><i class="mdi mdi-language-javascript"></i>Javascript</span>
            </div>
            <b-button v-b-modal.webmarket variant="warning" size="lg" class="rounded-5">Start Web Development
              <i class="mdi mdi-web"></i>
            </b-button>

          </b-col>
          <b-col cols="12" lg="5">
            <img src="../assets/web-development.png" alt="logistics" class="img-fluid">
          </b-col>
        </b-row>
        <b-row class="align-items-center pt-5">
          <b-col cols="12" lg="7">
            <h2 class="corg">Mobile App Development</h2>
            <p>
              Mobile app development services refer to the process of creating and designing software applications that are designed to run on mobile devices such as smartphones and tablets. These services are typically provided by mobile app development companies or freelance mobile app developers
            </p>
            <p>
              Mobile app development services include activities such as mobile app design, front-end development, back-end development, user interface design, user experience design, and mobile app testing. Mobile app developers may use a range of programming languages and frameworks, such as Java, Swift, Objective-C, Kotlin, and React Native, to build mobile apps.
            </p>
            <div class="dfwrap">
              <span><i class="mdi mdi-android"></i>Android</span>
              <span><i class="mdi mdi-apple"></i>IOS</span>
              <span><i class="mdi mdi-language-java"></i>Java</span>
            </div>
            <b-button v-b-modal.webmarket variant="warning" size="lg" class="rounded-5">Start Mobile App
              <i class="mdi mdi-android"></i>
            </b-button>
          </b-col>
          <b-col cols="12" lg="5">
            <img src="../assets/mobile-app-dev1.png" alt="logistics" class="img-fluid">
          </b-col>
        </b-row>
        <b-row class="align-items-center pt-5">
          <b-col cols="12" lg="7">
            <h2 class="corg">Digital Marketing</h2>
            <p>
              Digital marketing is the process of promoting products or services through digital channels, such as search engines, social media, email, and websites. It includes a range of strategies and tactics, such as search engine optimization (SEO), social media optimization (SMO), pay-per-click (PPC) advertising, content marketing, email marketing, and more
            </p>
            <p>
              SEO is the process of optimizing a website to improve its visibility in search engine results pages. This includes techniques such as keyword research, on-page optimization, link building, and more.
            </p>
            <p>
              SMO is the process of optimizing social media profiles and content to improve visibility and engagement on social media platforms such as Facebook, Twitter, Instagram, and LinkedIn. This includes techniques such as content creation, community building, and social media advertising.
            </p>
            <p>
              We provide SEO, SMO, PPC, content marketing, email marketing, and other digital marketing techniques. Digital marketing jobs can offer opportunities for career advancement and growth within the digital marketing industry.
            </p>
            <div class="dfwrap">
              <span><i class="mdi mdi-advertisements"></i>Advertisements</span>
              <span><i class="mdi mdi-google-ads"></i>Google Ads</span>
              <span><i class="mdi mdi-email-seal"></i>Email Marketing</span>
            </div>
            <b-button v-b-modal.webmarket variant="warning" size="lg" class="rounded-5">Start Marketing
              <i class="mdi mdi-google-ads"></i>
            </b-button>
            <b-modal id="webmarket" hide-footer hide-header size="lg">
              <StartWebdev/>
            </b-modal>
          </b-col>
          <b-col cols="12" lg="5">
            <img src="../assets/digital-marketing1.png" alt="logistics" class="img-fluid">
          </b-col>
        </b-row>
      </b-container>
      <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 19.6" preserveAspectRatio="none">
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"></path>
          <path class="shape-fill" style="opacity:0.33" d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"></path>
          <path class="shape-fill" d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"></path>
        </svg>
      </div>
    </section>
    <section id="partner" class="py-5">
      <b-container>
        <h2 class="text-center display-5 fw-semibold">Service Providers</h2>
        <b-row class="justify-content-center g-5 pt-5">
          <b-col cols="12" lg="4" v-for="partner in partners" :key="partner.id">
            <b-card overlay
                :img-src="('https://dummyimage.com/600x400/ffa500/ffa500.png&text='+partner.name)"
                :img-alt="partner.name" text-variant="black" :title="partner.name">
            </b-card>
          </b-col>
        </b-row>

      </b-container>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import ApplyJob from "@/views/forms/ApplyJob";
import BookParcelnow from "@/views/forms/BookParcelnow";
import BookCleaningservice from "@/views/forms/BookCleaningservice";
import StartWebdev from "@/views/forms/StartWebdev";

export default {
  name: 'HomeView',
  components: {StartWebdev, BookCleaningservice, BookParcelnow, ApplyJob},
  data() {
    return{
      tab:null,
      url:'https://cdn.sandgate-recruit.co.uk',
      services:'',
      jobcats:[],
      jobtypes:[],
      partners:[
        {id:1,name:'Sandgate Recruitment Services',img_url:'./staffing-banner-300x131.jpg'},
        {id:2,name:'Care4u Home Care',img_url:'/staffing-banner-300x131.jpg'},
        {id:3,name:'Josh Cleaning Services',img_url:'/staffing-banner-300x131.jpg'},
      ]
    }
  },
  methods:{
    getServices(){
      axios.get('services')
          .then((resp)=>{
            this.services = resp.data.services;
          });
    },
    getJobcats(){
      axios.get('jobcats')
          .then((resp)=>{
            this.jobcats = resp.data.jobcats;
          });
    },
    getJobtypes(){
      axios.get('jobtypes')
          .then((resp)=>{
            this.jobtypes = resp.data.jobtypes;
          })
    }
  },
  created() {
    this.getServices();
    this.getJobcats();
    this.getJobtypes();
  }
}
</script>
<style scoped>
.ls1{letter-spacing:1px;}
#logistics{
  background-image: url(../assets/logistics1.jpg);
  background-size: cover;
  background-position: top right;
  background-blend-mode: overlay;
  background-color: rgba(255,255,255,0.95);
  background-attachment: fixed;
}
#facs{
  background-image: url(../assets/cleaning1.jpg);
  background-size: cover;
  background-position: top right;
  background-blend-mode: overlay;
  background-color: rgba(255,255,255,0.9);
  background-attachment: fixed;
}
#tech{
  background-image: url(../assets/java-developer.jpg);
  background-size: cover;
  background-position: top right;
  background-blend-mode: overlay;
  background-color: rgba(255,255,255,0.95);
  background-attachment: fixed;
}
.shape.shape-bottom{transform: rotate(180deg);}
.shape{fill:var(--org);}
.tbs {
  text-shadow: 0 0 2px rgba(0,0,0,0.3);
  letter-spacing: 0.8px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}
</style>
