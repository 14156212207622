import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'home', component: HomeView},
  {path: '/about', name: 'about', component: () => import('../views/AboutView')},
  {path: '/contact', name: 'contact', component: () => import('../views/ContactView')},
  {path: '/are-you-employer', name: 'areyouemployer', component: () => import('../views/EmployersView')},
  {path: '/job/category/:jobcat_url', name: 'jobcat', component: () => import('../views/JobcatsView'), props:true},
  {path: '/job/type/:jobtype_slug', name: 'jobtype', component: () => import('../views/JobtypesView'), props:true},
  {path: '/job/detail/:jobid', name: 'jobdetail', component: () => import('../views/JobView'),props: true},
  {path: '/job-results', name: 'jobresults', component: () => import('../views/JobResults')},

  {path: '/admin/login',name:'adminlogin',component:()=>import('@/views/admin/AdminLogin')},
  {path: '/admin',name:'admindashboard',component:()=>import('@/views/admin/AdminDashboard'),
      children:[
          {path:'categories',name:'admincats', component:()=>import('@/views/admin/AdminCats')},
          {path:'category/add',name:'admincatadd', component:()=>import('@/views/admin/AdmincatAdd')},
          {path:'jobs',name:'adminjobs', component:()=>import('@/views/admin/AdminJobs')},
          {path:'job/add',name:'adminjobadd', component:()=>import('@/views/admin/AdminjobAdd')},
          {path:'jobtypes',name:'adminjobtypes', component:()=>import('@/views/admin/AdminJobtype')},
          {path:'employers',name:'adminemployees', component:()=>import('@/views/admin/AdminEmployers')},
          {path:'candidates',name:'admincandidates', component:()=>import('@/views/admin/AdminCandidates')},
          {path:'services',name:'adminservices', component:()=>import('@/views/admin/AdminServices')},
          {path:'states',name:'adminstates', component:()=>import('@/views/admin/AdminStates')},
          {path:'cities',name:'admincities', component:()=>import('@/views/admin/AdminCities')},
          {path:'pages',name:'adminallpages', component:()=>import('@/views/admin/AllPages')},
          {path:'page/add',name:'adminpageadd', component:()=>import('@/views/admin/PageNew')},
      ]},

    {path: '/employer/login',name: 'emplogin',component: ()=>import('@/views/employer/EmpLogin')},
    {path: '/employer',name: 'empdashboard',component: ()=>import('@/views/employer/EmpSidebar'),
    children: [
        {path: 'jobs',name: 'empjobs',component:()=>import('@/views/employer/EmpJobs')},
    ]}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
