<template>
  <v-app class="sandgate">
    <v-main class="pt-0">
      <MenuBar/>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import MenuBar from "@/views/MenuBar";
// import FooterView from "@/views/FooterView";
export default {
  name:'App',
  components: {MenuBar},
  metaInfo: {
    title: 'Sandgate Recruit UK',
    titleTemplate: '%s | Get Dream Job, Sandgate-recruit.co.uk™',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
  data() {
    return{

    }
  }
}
</script>

<style>
:root{
  --org:#ffa500;
  --bs-navbar-hover-color: #ffa500;
  --bs-body-font-family: 'Fredoka', sans-serif !important;
  --bs-font-sans-serif: 'Fredoka', sans-serif !important;
}
body{font-family: var(--bs-body-font-family);}
body .sandgate {font-family: var(--bs-body-font-family);}
.borg{background-color: var(--org) !important;}
.corg{color: var(--org) !important;}
.nav-link:hover, .nav-link:focus {
  color: var(--org) !important;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {color: var(--org);}
.dfwrap {
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 15px;
}
.dfwrap span {
  margin-right: 10px;
  background: #fff;
  padding: 2px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.dfwrap span i {
  color: var(--org);
  margin-right: 5px;
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@100;200;300;400;500;600;700;800&display=swap');
$font-family:'Fredoka';
.sandgate {
  .headline, .v-application,
  [class*='display-'],
  [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
</style>
