import { render, staticRenderFns } from "./BookParcelnow.vue?vue&type=template&id=5670afdc&scoped=true&"
import script from "./BookParcelnow.vue?vue&type=script&lang=js&"
export * from "./BookParcelnow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5670afdc",
  null
  
)

export default component.exports