<template>
  <form @submit="onSubmit" v-if="show">
    <h4>Book Parcel Now</h4>
    <div class="row mb-3">
      <b-form-group id="name" label="First Name:" label-for="name" class="col-sm-6">
        <b-form-input id="name" v-model="form.name" type="text" required></b-form-input>
      </b-form-group>
      <b-form-group id="destination" label="Destination:" label-for="destination" class="col-sm-6">
        <b-form-input id="destination" v-model="form.destination" type="text" required></b-form-input>
      </b-form-group>
    </div>
    <div class="row mb-3">
      <b-form-group id="phone" label="Mobile Phone:" label-for="phone" class="col-sm-6">
        <b-form-input id="phone" v-model="form.phone" type="number" required></b-form-input>
      </b-form-group>
      <b-form-group id="email" label="Email address:" label-for="email" class="col-sm-6">
        <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
      </b-form-group>
    </div>
    <div class="row mb-3">
      <b-form-group id="parcels" label="No of Parcels:" label-for="parcels" class="col-sm-6">
        <b-form-input id="parcels" v-model="form.parcels" type="number" required></b-form-input>
      </b-form-group>
      <b-form-group id="bdate" label="Booking Date:" label-for="bdate" class="col-sm-6">
        <b-form-datepicker id="bdate" v-model="form.bdate" required locale="en"></b-form-datepicker>
      </b-form-group>
    </div>

    <b-button type="submit" variant="danger" class="mt-3">Submit</b-button>
  </form>
</template>

<script>
export default {
  name: "BookParcelnow",
  data() {
    return {
      form:{
        name:'',
        destination:'',
        email:'',
        phone:'',
        parcels:'',
        bdate:'',
      },
      show:true,
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
  }
}
</script>

<style scoped>

</style>